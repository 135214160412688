import React from "react";
import { Outlet } from "react-router-dom";
import AppSidebar from "../components/Sidebar";




const AppLayout = () => {
 
    return (
        <>
            <div className="main">
                <div className="wrapper">
                    <AppSidebar/>
                    
                    <Outlet/>
                    <div className="right-panel"></div>
                  
                </div>
            </div>
            
        </>
    );
}
export default AppLayout;