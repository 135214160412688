import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
// import {auth} from "../firebase"
// import { signOut } from "firebase/auth";
import Auth from "./Auth";

const AppSidebar = () => {
  const navigate = useNavigate();
  const { logout } = Auth();
  const [activeCompo, setActive] = useState();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const checkUserToken = () => {
    const userToken = sessionStorage.getItem("userToken");
    // console.log(userToken);
    if (!userToken || userToken === "undefined") {
      setIsLoggedIn(false);
    } else {
      setIsLoggedIn(true);
    }
  };
  // const userSignOut=()=>{
  //   signOut(auth).then(()=>{
  //     console.log('user signout successfully.');
  //     navigate('/login')

  //   })
  // }

  useEffect(() => {
    checkUserToken();
  }, []);

  const handleActive = (comp) => {
    setActive(comp);
  };

  return (
    <>
      <div className="left-panel">
        <div className="menu-toggle">
          <a href="#">
            <i className="fa-light fa-bars" />
          </a>
        </div>
        <div className="logo">
          <Link to={"/dashboard"}>
            <img src="/images/logo.svg" alt="img" className="img-fluid" />
          </Link>
        </div>
        <div className="menu">
          {isLoggedIn == true ? (
            <ul>
              <li>
                <Link className={activeCompo === "home" ? "active" : ""} onClick={() => handleActive("home")} to={"/dashboard"}>
                  Home
                </Link>
              </li>

              <li>
                <Link className={activeCompo === "notification" ? "active" : ""} onClick={() => handleActive("notification")} to={"/notification"}>
                  Notifications
                </Link>
              </li>
              <li>
                <Link className={activeCompo === "bookmarks" ? "active" : ""} onClick={() => handleActive("bookmarks")} to={"/bookmarks"}>
                  Bookmarks
                </Link>
              </li>
              <li>
                <Link className={activeCompo === "settings" ? "active" : ""} onClick={() => handleActive("settings")} to={"/settings"}>
                  Settings
                </Link>
              </li>
              <li>
                <Link className={activeCompo === "profile" ? "active" : ""} onClick={() => handleActive("profile")} to={"/profile"}>
                  Profile
                </Link>
              </li>
              <li>
                <a href="javascript:void(0);" onClick={() => logout()}>
                  Logout
                </a>
              </li>
            </ul>
          ) : (
            <ul>
              <li>
                <Link className={activeCompo === "home" ? "active" : ""} onClick={() => handleActive("home")} to={"/dashboard"}>
                  Home
                </Link>
              </li>

              <li>
                <Link className={activeCompo === "login" ? "active" : ""} onClick={() => handleActive("login")} to={"/login"}>
                  Login
                </Link>
              </li>
              <li>
                <Link className={activeCompo === "signup" ? "active" : ""} onClick={() => handleActive("signup")} to={"/signup"}>
                  Sign Up
                </Link>
              </li>
            </ul>
          )}
        </div>
        <div className="vent-now-btn">
          <Link to={"/new-topic"} className="btn btn-black">
            Vent Now
          </Link>
        </div>
      </div>
    </>
  );
};

export default AppSidebar;
