import React,{useEffect,useState} from 'react'
import { Link } from 'react-router-dom'
import Auth from "../components/Auth"

function Notification() {

  const {http,logout}=Auth()
  const [data,setData]=useState()
  const [loading, setLoading] = useState(true); 

  const getFetchData=()=>{
    let reqUrl=`/getNotifications`;
      http.get(reqUrl).then((res)=>{
         
          if(!res.data.status){
            logout()
          }
          setData(res.data.data)
          setLoading(false)
      }).catch(function (error) {

        console.log(error);
        // if(error.response.status==401){
          
        // }
      })
    }
  useEffect(() => {
    getFetchData()
  }, []);


  return (
    <div className="center-panel">
        {/* <div className="search">
            <input type="text" placeholder="Search" />
        </div> */}
        <div className="topics-wrapper">
        {
        loading ? (
          <div className="box">
            <div className="loader">
              <span className="dot"></span>
              <span className="dot"></span>
              <span className="dot"></span>
              <span className="dot"></span>
            </div>
          </div>
      ):
        data?Object.keys(data).map((key) => (
             <div className="notification-block" >
                {/* <Link className="">{data[key].message}</Link> */}
                {/* <Link to={data[key].topic_link}  className="notification">{data[key].message}</Link> */}

                <div dangerouslySetInnerHTML={{ __html: data[key].message }} />
             </div>
          )):
            <div className='not-found-msg'>
                Notification not found
              </div>
            }
    
       

        {/* <div className="topic-block" >
        <Link to="" className="title"></Link>
        <a href="#" style={{fontWeight : 'bold'}} className="user">John Downy</a>
        <span className="comments">React on your post.</span>
        </div>

        <div className="topic-block" >
        <Link to="" className="title"></Link>
        <a href="#" style={{fontWeight : 'bold'}} className="user">Andrew deo</a>
        <span className="comments">Liked your post.</span>
        </div>

        <div className="topic-block" >
        <Link to="" className="title"></Link>
        <a href="#" style={{fontWeight : 'bold'}} className="user">Cathy Laron</a>
        <span className="comments">Liked your comment.</span>
        </div>

        <div className="topic-block" >
        <Link to="" className="title"></Link>
        <a href="#" style={{fontWeight : 'bold'}} className="user">Larry decard</a>
        <span className="comments">React on your post.</span>
        </div>

        <div className="topic-block" >
        <Link to="" className="title"></Link>
        <a href="#" style={{fontWeight : 'bold'}} className="user">Sithiya Michal</a>
        <span className="comments">Liked your post.</span>
        </div>

        <div className="topic-block" >
        <Link to="" className="title"></Link>
        <a href="#" style={{fontWeight : 'bold'}} className="user">Oliver Ranson </a>
        <span className="comments">Liked your comment.</span>
        </div> */}
    
           

           
        </div>
    </div> 
  )
}

export default Notification