
import React,{useState,useEffect} from "react";
import { Link } from "react-router-dom";
import Auth from "../../components/Auth";



const Settings = () => {


  const {http, logout} = Auth();
  const [user, setUser] = useState(false)

  const getFetchData=()=>{
      http.get('/getCurrentUser').then((res)=>{
        //console.log(res.data);
          // if(!res.data.status){
          //   logout();
          // }
          setUser(res.data.user)
          
      }).catch(function (error) {
        console.log(error);
      })
    }
  useEffect(() => {
    getFetchData()
  }, []);
  return (
    <>
    <div className="center-panel">
        <div className="page-content">
          <div className="page-head">
            <h1>Settings</h1>
          </div>
          <div className="page-body">
            <div className="settings">
              <div className="block">
                <div className="left">
                  <h4>Username</h4>
                  
                  <p>@{user?.displayName}</p>

                </div>
                <div className="right">
                  <Link to={"/settings/change-username"}>Change</Link>
                </div>
              </div>
              <div className="block">
                <div className="left">
                  <h4>Email</h4>
                  <p>{user?.email}</p>
                </div>
                <div className="right">
                  <Link to={"/settings/change-email"}>Change</Link>
                </div>
              </div>
              <div className="block">
                <div className="left">
                  <h4>Password</h4>
                  <p>********</p>
                </div>
                <div className="right">
                  <Link to={"/settings/change-password"}>Change</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
    </>
  );
}

export default Settings