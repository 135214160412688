import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { auth } from "../firebase";
import { onAuthStateChanged, signOut } from "firebase/auth";
import Auth from "../components/Auth";

const Dashboard = () => {
  const { http, logout } = Auth();

  const [authUser, setAuthUser] = useState(null);
  const [search, setSearch] = useState("");
  const [topics, setTopics] = useState();
  useEffect(() => {
    const listen = onAuthStateChanged(auth, (user) => {
      if (user) {
        console.log(user);
        setAuthUser(user);
      } else {
        setAuthUser(null);
      }
    });
  }, []);

  const userSignOut = () => {
    signOut(auth).then(() => {
      console.log("user signout successfully.");
    });
  };

  const getFetchData = () => {
    let reqUrl = `/getTopic`;
    http
      .get(reqUrl)
      .then((res) => {
        if (!res.data.status) {
          logout();
        }
        setTopics(res.data.data);
      })
      .catch(function (error) {
        console.log(error);
        // if(error.response.status==401){

        // }
      });
  };
  useEffect(() => {
    getFetchData();
  }, []);

  const getVal = (e) => {
    console.log(e.target.value);
  };
  // const getvalue = () =>{
  //   console.log(search)
  // }

  //const displayData= topics?topics.map((topic,key)=>{
  // console.log(topic)
  //   return (
  //    <div className="topic-block" key={key}>
  //    <Link to={'/single-topic'} className="title">{topic.title}</Link>
  //    <a href="#" className="user">@john_doe</a>
  //    <span className="comments">53 comments</span>
  //    </div>
  //   )
  //  })
  //  :''

  return (
    <>
      <div className="center-panel">
        <div className="search">
          <input type="text" placeholder="Search" onChange={getVal} />
        </div>

        <div className="topics-wrapper">
          {topics ? (
            Object.keys(topics).map((key) => (
              <div className="topic-block" key={key}>
                <Link to={"/topic/" + topics[key].slug} className="title">
                  {topics[key].title}
                </Link>
                <Link to={`/public-profile/${topics[key].user}`} className="user">
                  @{topics[key].user}
                </Link>
                <span className="comments">{topics[key].comments} comments</span>
              </div>
            ))
          ) : (
            <>
              <div className="box">
                <div className="loader">
                  <span className="dot"></span>
                  <span className="dot"></span>
                  <span className="dot"></span>
                  <span className="dot"></span>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Dashboard;
