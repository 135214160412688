
import React,{useState,useEffect} from "react";
import { useForm } from "react-hook-form";
import Auth from "../../components/Auth"



const ChangeUsername = () => {
  const [showOtp, setShowOtp] = useState(false);
  const { register, handleSubmit,formState: { errors },reset,watch} = useForm();
  const [btnLoader,setBtnLoader]=useState(false)
  const [resError, setResError] = useState('');
  const [resSuccess, setResSuccess] = useState('');
  const [email, setEmail] = useState('');
  const [canResend, setCanResend] = useState(true); 
  const [countdown, setCountdown] = useState(30);
  const {http}=Auth();

  const onSubmit = (data) => {
    setBtnLoader(true)

    var reqUrl=showOtp?'change-email':'change-email-request'
    if(showOtp){
      data.email=email
    }
    http.post(`/${reqUrl}`,data).then((res)=>{
        if (res.data.statusCode===403) {
          setResError(res.data.message)
        } else if (res.data.statusCode===200){
         reset()
         if(showOtp){
          setResSuccess(' email updated successfully')
          setEmail('');
          setShowOtp(false)
          setResError('')
         }else{
          setResSuccess(' has been set as your email address')
          setEmail(data.email);
          setShowOtp(true)
          setResError('')
          setCanResend(false);
          setTimeout(() => {
            setCanResend(true);
            setCountdown(30);
          }, 30000);


         }
        
        }
        setBtnLoader(false)
      }).catch(function (error) {
        setBtnLoader(false)
      })
  };

  const handleResendClick = () => {
    if (canResend) {
      //console.log('Opt resent');
      setCanResend(false);
      setTimeout(() => {
        setCanResend(true);
        setCountdown(30);
      }, 30000);
      let resendData={email:email};
      console.log(resendData);
      http
      .post("/resend-email-request", resendData)
      .then((res) => {
        setBtnLoader(false);
        console.log(res.data.error);
        if (res.data.statusCode==='403') {
          setResError(res.data.message);
        } 
      })

     
    }
  };
  useEffect(() => {
    if (!canResend) {
      const countdownTimer = setInterval(() => {
        setCountdown(prevCountdown => prevCountdown - 1);
      }, 1000);

      return () => {
        clearInterval(countdownTimer);
      };
    }
  }, [canResend]);

  return (
    <>
     <div className="center-panel">
        <div className="page-content">
          <div className="page-head">
            <h1>Change Email Address</h1>
          </div>
          <div className="page-body">
            <div className="form-block">
              <form onSubmit={handleSubmit(onSubmit)}>
                { showOtp?
                <>  
                  <div className="row mt-4 mb-1">
                    <div className="col-12">
                      <p>Please enter 6-digit code has been sent to your new email address</p>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-12 col-md-12 col-lg-6">
                      <label htmlFor="otp" className="form-label">Enter 6-digit code</label>
                      <input type="text" className="form-control" id="otp"  {...register("otp", { required: true })} />
                      {
                        canResend?
                        <p className="resend-btn" onClick={handleResendClick}>
                          Resend Otp
                        </p >:
                        <p className="disable-resend-btn" disabled="false" >
                          Resend in {countdown} seconds
                      </p >
                      }

                      {resError && <div className='text-danger text-left'>{resError}</div>}
                      {errors.otp && (
                        <p className="error-text">Otp is required</p>
                      )}
                    </div>
                  </div>
                  <div className="row mb-5">
                    <div className="col-12">
                      
                      {  btnLoader ?
                      <button className="btn btn-primary" type="button">
                        Loading..
                        <span className="spinner-border spinner-border-sm"></span>
                      </button> 
                    :
                    <button type="submit" className="btn btn-black">Verify</button>
                    }
                    </div>
                  </div>
                </>
                
                :
                <>
                <div className="row mb-3">
                  <div className="col-12 col-md-12 col-lg-6">
                    <label htmlFor="email" className="form-label">Enter Email Address</label>
                    <input type="text" className="form-control" id="email"  {...register("email", { required: true })}/>
                    {resError && <div className='text-danger text-left'>{resError}</div>}
                    {errors.email && (
                        <p className="error-text">Email is required</p>
                      )}
                  </div>
                </div>
                <div className="row mb-5">
                  <div className="col-12">
                   
                   

                    {  btnLoader ?
                      <button className="btn btn-primary" type="button">
                        Loading..
                        <span className="spinner-border spinner-border-sm"></span>
                      </button> 
                    :
                      <button type="submit" className="btn btn-black">Change</button>
                    }
                  </div>
                </div>
                </>

                }
              
                
                <div className="row mb-3 success-row">
                  <div className="col-12">
                  {resSuccess &&  <p><i className="fa-sharp fa-solid fa-circle-check" /><strong>@{email}</strong> {resSuccess}</p>}
                    {/* <p><i className="fa-sharp fa-solid fa-circle-check" /> <strong>newemailjohn@gmail.com</strong> has been set as your email address</p> */}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ChangeUsername