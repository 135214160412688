
import React,{useState} from "react";
import { useForm } from "react-hook-form";
import Auth from "../components/Auth"
import { Link,useParams,useNavigate } from "react-router-dom";



const ChangeUsername = () => {
  const {http}=Auth();
  const {uid,token}=useParams()
  const navigate=useNavigate()

  const { register, handleSubmit,formState: { errors },reset , watch } = useForm();
  const [btnLoader,setBtnLoader]=useState(false)
  const [resError, setResError] = useState('');
  const [resSuccess, setResSuccess] = useState('');

  const onSubmit = (data) => {
    data.user_id=uid
    data.token=token
    http.post('/update-password',data).then((res)=>{
        if (res.data.statusCode===409) {
          setResError(res.data.message)
        } else if (res.data.statusCode===200){
          setResSuccess(res.data.message)
          navigate('/login')
        }
        setBtnLoader(false)
      }).catch(function (error) {
        setBtnLoader(false)
      })
  };
  const newPassword = watch("password");



  return (
    <>
        <div className="form-block login-sec">
            <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row mb-3">
                <div className="col-12">
                <Link href="#"><img src="/images/logo.svg" alt="img" className="img-fluid" /></Link>
                </div>
            </div>
            <div className="row my-5">
                <div className="col-12">
                <h3>Update your password</h3>
                {resError?<p className="text-danger">{resError}</p>:''}<></>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-12 col-md-12 col-lg-12">
                <label htmlFor="password" className="form-label">Enter New Password</label>
                <input type="password" className="form-control" id="password"  {...register("password", { required: true })} />
                {errors.password && (
                    <p className="error-text">New Password is required</p>
                )}
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-12 col-md-12 col-lg-12">
                <label htmlFor="confirm_password" className="form-label">Confirm New Password</label>
                <input type="password" className="form-control" id="confirm_password" {...register("confirmPassword", {
                required: true,
                validate: (value) => value === newPassword, 
            })} />
                {errors.confirmPassword?.type === "required" && (
                <p className="error-text">Confirm Password is required</p>
                )}
                {errors.confirmPassword?.type === "validate" && (
                <p className="error-text">Passwords do not match</p>
                )}
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-12">


                {  btnLoader ?
                    <button className="btn btn-primary" type="button">
                    Loading..
                    <span className="spinner-border spinner-border-sm"></span>
                    </button> 
                :
                <button type="submit" className="btn btn-black">Update</button>

                }
                
                </div>
            </div>
            <div className="row mb-3 success-row">
                <div className="col-12">
                {resSuccess &&  <p><i className="fa-sharp fa-solid fa-circle-check" /> Password changed successfully</p>}
                
                </div>
            </div>
            </form>
        </div>
    </>
  );
}

export default ChangeUsername