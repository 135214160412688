import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
// import { Link, useLocation } from "react-router-dom";
import { auth } from "../firebase";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { useForm } from "react-hook-form";
import Auth from "../components/Auth";

const Signup = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { basehttp } = Auth();
  const [btnLoader, setBtnLoader] = useState(false);
  const [submitError, setSubmitError] = useState({});
  const [suggestions, setSuggestions] = useState([]);
  const [selectedSugg, setSelectedSugg] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [resError, setResError] = useState("");
  const navigate = useNavigate();

  // const signUp=(e)=>{
  //   e.preventDefault()
  //   createUserWithEmailAndPassword(auth,email,password)
  //   .then((userCredential)=>{
  //     console.log(userCredential)
  //   }).catch((error)=>{
  //     console.log(error)
  //   })
  // }
  //
  useEffect(() => {
    setBtnLoader(true);
    basehttp
      .get("/get-usernames")
      .then((res) => {
        setBtnLoader(false);
        // console.log(res.data.error);
        if (res.data.statusCode === 200) {
          setSuggestions(res.data.data);
          setIsLoading(false);
        }
        //
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 422) {
          console.log(error.response.data);
          setSubmitError(error.response.data);
        } else if (error.response.status === 401) {
        }
        setBtnLoader(false);
      });
  }, []);
  const getSuggestions = () => {
    setBtnLoader(true);
    basehttp
      .get("/get-usernames")
      .then((res) => {
        setBtnLoader(false);
        // console.log(res.data.error);
        if (res.data.statusCode === 200) {
          setSuggestions(res.data.data);
          setIsLoading(false);
        }
        //
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 422) {
          console.log(error.response.data);
          setSubmitError(error.response.data);
        } else if (error.response.status === 401) {
        }
        setBtnLoader(false);
      });
  };
  //getSuggestions();
  const submitForm = (data) => {
    setBtnLoader(true);
    data.displayName = selectedSugg;
    basehttp
      .post("/register", data)
      .then((res) => {
        setBtnLoader(false);
        console.log(res.data.error);
        if (res.data.statusCode === 409) {
          setResError(res.data.message);
          if (res.data && res.data.data && res.data.data.suggestions) {
            setSuggestions(res.data.data.suggestions);
          }
        } else {
          localStorage.setItem("verify_email", data.email);
          navigate("/verify-email");
        }
        //
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 422) {
          console.log(error.response.data);
          setSubmitError(error.response.data);
        } else if (error.response.status === 401) {
        }
        setBtnLoader(false);
      });
  };

  return (
    <>
      <div className="form-block login-sec">
        <form onSubmit={handleSubmit(submitForm)}>
          <div className="row mb-3">
            <div className="col-12">
              <Link href="#">
                <img src="/images/logo.svg" alt="img" className="img-fluid" />
              </Link>
            </div>
          </div>
          <div className="row my-5">
            <div className="col-12">
              <h3>Create Your Account</h3>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-12">
              <label htmlFor="email_address" className="form-label">
                Email Address
              </label>
              <input type="text" className="form-control" id="email_address" placeholder="johndoe@gmail.com" {...register("email", { required: true })} />
              {errors.email && <div className="text-danger text-left">This field is required</div>}
              {submitError.email && <div className="text-danger text-left">{submitError.email[0]}</div>}
              {submitError.error && <div className="text-danger text-left">{submitError.error}</div>}
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <label htmlFor="password" className="form-label">
                Password
              </label>
              <input
                type="password"
                className="form-control"
                id="password"
                placeholder="********"
                {...register("password", {
                  required: "Password is required",
                  minLength: {
                    value: 6,
                    message: "Password must be at least 6 characters long",
                  },
                })}
              />
              {errors.password && <div className="text-danger text-left">{errors.password.message}</div>}
              {submitError.email && <div className="text-danger text-left">{submitError.email[0]}</div>}
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-12 mt-2">
              <label Style="width:100%" htmlFor="username_address" className="form-label">
                Username{" "}
                <Link onClick={() => getSuggestions()} to={"javascript:;"} className="text-right">
                  Regenerate
                </Link>
              </label>

              <div className="main-suggestion mt-2">
                {/* <label htmlFor="username_address" className="form-label">Choose Username</label> */}
                <select className="form-control" onChange={(e) => setSelectedSugg(e.target.value)}>
                  <option value="">Choose Username</option>
                  {suggestions.map(
                    (sugg, key) => (
                      <option value={sugg}>{sugg}</option>
                    ),
                    //  <span className="suggestion" key={key} onClick={() => setSelectedSugg(sugg)}>{sugg}</span>
                  )}
                </select>
              </div>
              {errors.displayName && <div className="text-danger text-left">This field is required</div>}
              {submitError.displayName && <div className="text-danger text-left">{submitError.displayName[0]}</div>}
              {submitError.error && <div className="text-danger text-left">{submitError.error}</div>}
              {resError && <div className="text-danger text-left">{resError}</div>}
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              {/* <Link to={'/dashboard'}> */}

              {btnLoader ? (
                <button className="btn login-btn" type="button">
                  Loading..
                  <span className="spinner-border spinner-border-sm"></span>
                </button>
              ) : (
                <button type="submit" className="btn btn-black">
                  Sign Up
                </button>
              )}
              {/* </Link> */}
            </div>
          </div>
          <hr />
          <div className="row mb-3">
            <div className="col-12">
              <p>
                Have an account ?{" "}
                <strong className="navigation" onClick={() => navigate("/login")}>
                  Log in
                </strong>
              </p>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default Signup;
