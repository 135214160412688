import React, { useState, useEffect, Component } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Auth from "../components/Auth";

const NewTopic = () => {
  const { username } = useParams();
  const { http, logout } = Auth();
  const [user, setUser] = useState(false);
  const [id, setId] = useState();
  const [follow, setFollow] = useState(false);

  const getFetchData = () => {
    let data = {
      username: username,
    };
    http
      .post("/publicProfile", data)
      .then((res) => {
        if (!res.data.status) {
          logout();
        }
        setUser(res.data.data);
        setId(res.data.data.user_id);
        setFollow(res.data.data.is_followed);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    getFetchData();
  }, []);

  const followUnfollow = () => {
    // console.log(id)
    var requrl = follow ? "/unfollow-user" : "/follow-user";
    let data = { user_id: id };
    http
      .post(requrl, data)
      .then((res) => {
        if (follow) {
          setFollow(false);
        } else {
          setFollow(true);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <>
      <div className="center-panel">
        {user ? (
          <div className="page-content">
            <div className="page-head profile-head">
              <div className="row">
                <div className="col-6 col-md-8 d-flex align-items-center">
                  <h1>@{user.username}</h1>
                </div>
                <div className="col-6 col-md-4 align-right">
                  {follow ? (
                    <p onClick={followUnfollow} className="btn btn-black btn-following">
                      Following
                    </p>
                  ) : (
                    <p onClick={followUnfollow} className="btn btn-black btn-follow">
                      Follow
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="page-body">
              <div className="topics-wrapper">
                {user ? (
                  Object.keys(user.topics).map((k) => (
                    <div className="topic-block">
                      <Link to={"/topic/" + user.topics[k].slug} className="title">
                        {user.topics[k].title}
                      </Link>
                      <Link href="#" className="user">
                        @{user.topics[k].user}
                      </Link>
                      <span className="comments">{user.topics[k].comments} comments</span>
                    </div>
                  ))
                ) : (
                  <div className="box">
                    <div className="loader">
                      <span className="dot"></span>
                      <span className="dot"></span>
                      <span className="dot"></span>
                      <span className="dot"></span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="box">
            <div className="loader">
              <span className="dot"></span>
              <span className="dot"></span>
              <span className="dot"></span>
              <span className="dot"></span>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default NewTopic;
