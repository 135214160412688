import { useState } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";

export default function Auth() {
    const navigate = useNavigate();
    const { state } = useLocation();
    const [token, setUserToken] = useState();
    //const [user,setUser]=useState();

    const isUserLoggedIn = () => {
        const userToken = sessionStorage.getItem("userToken");
    };

    const saveUseToken = (token) => {
        sessionStorage.setItem("userToken", JSON.stringify(token));
        // sessionStorage.setItem('user',JSON.stringify(user))
        setUserToken(token);
        //setUser(user);
        if (token) {
            navigate("/dashboard");
        }
    };
    const getToken = () => {
        const tokenString = sessionStorage.getItem("userToken");
        const adminToken = JSON.parse(tokenString);
        return adminToken;
    };
    const isAdmin = () => {
        const isAdminString = sessionStorage.getItem("isAdmin");
        const isAdmin = JSON.parse(isAdminString);
        return isAdmin;
    };

    const getAdmin = () => {
        const adminString = sessionStorage.getItem("admin");
        const adminDetails = JSON.parse(adminString);
        return adminDetails;
    };

    const basehttp = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        headers: {
            "Content-type": "application/json",
            //"Authorization" : `Bearer ${getToken()}`
        },
    });

    const http = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${getToken()}`,
        },
    });

    const logout = () => {
        // console.log('hello')

        if (getToken()) {
            sessionStorage.clear();
            console.log(getToken());
            navigate("/login");
        } else {
            navigate("/login");
        }
    };
    const privateRoutes = () => {
        if (!token) {
            console.log("redirect on login");
            navigate("/login");
        }
    };

    return {
        setUserToken: saveUseToken,
        token,
        getToken,
        getAdmin,
        basehttp,
        http,
        logout,
        privateRoutes,
        isAdmin,
    };
}
