import React,{useState} from "react";
import { useForm } from "react-hook-form";
import Auth from "../../components/Auth"


const ChangeUsername = () => {

  const { register, handleSubmit,formState: { errors },reset,watch} = useForm();
  const [btnLoader,setBtnLoader]=useState(false)
  const [suggestions, setSuggestions] = useState();
  const [selectedSugg, setSelectedSugg] = useState();
  const [resError, setResError] = useState('');
  const [resSuccess, setResSuccess] = useState('');
  const [username, setUsername] = useState('');
  
  const {http}=Auth();

  const onSubmit = (data) => {
    setBtnLoader(true)
    data.username=selectedSugg
    http.post('/change-username',data).then((res)=>{
        if (res.data.statusCode===409) {
          setResError(res.data.message)
          if(res.data &&  res.data.data && res.data.data.suggestions){
              setSuggestions(res.data.data.suggestions)
          }
        } else if (res.data.statusCode===200){
         //console.log('asdfasdffasdf')
         reset()
         setResSuccess(' has been set as your username')
         setSuggestions()
         setResError('')
         setUsername(data.username);
        }
        setBtnLoader(false)
      }).catch(function (error) {
        setBtnLoader(false)
      })
  };
  const newPassword = watch("password");
  
  return (
    <>
    <div className="center-panel">
        <div className="page-content">
          <div className="page-head">
            <h1>Change Username</h1>
          </div>
          <div className="page-body">
            <div className="form-block">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row mb-3">
                  <div className="col-12 col-md-12 col-lg-6">
                    <label htmlFor="username" className="form-label">Enter new username</label>
                    <input type="text" className="form-control" id="username"
                     {...register("username", { required: true })}
                        value={selectedSugg} 
                        onChange={(e) => setSelectedSugg(e.target.value)}
                     />
                    {suggestions?(
                      <div className="main-suggestion mt-2">
                          {/* <label htmlFor="username_address" className="form-label">Choose Username</label> */}
                        <select  className="form-control" onChange={(e) => setSelectedSugg(e.target.value)}>
                        <option value="">Choose Username</option>
                        { suggestions.map((sugg,key)=>
                          <option value={sugg}>{sugg}</option>
                          //  <span className="suggestion" key={key} onClick={() => setSelectedSugg(sugg)}>{sugg}</span>
                        )}
                        </select>
                      </div>
                    )
                    :''
                    }
                    {errors.username && (
                        <p className="error-text">Username is required</p>
                      )}
                        {resError && <div className='text-danger text-left'>{resError}</div>}
                    {/* <span className="error-text">@johnwrong is not available</span> */}
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-12">

                  {  btnLoader ?
                      <button className="btn btn-primary" type="button">
                        Loading..
                        <span className="spinner-border spinner-border-sm"></span>
                      </button> 
                    :
                    <button type="submit" className="btn btn-black">Submit</button>

                    }
                  </div>
                </div>
                <div className="row mb-3 success-row">
                  <div className="col-12">

                  {resSuccess &&  <p><i className="fa-sharp fa-solid fa-circle-check" /><strong>@{username}</strong> {resSuccess}</p>}
                    
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ChangeUsername