import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function Verfied() {
  const navigate = useNavigate();

  useEffect(() => {
    const redirectTimeout = setTimeout(() => {
      navigate("/dashboard");
    }, 2000);
    return () => clearTimeout(redirectTimeout);
  });
  return (
    <>
      <div className="form-block login-sec">
        <div className="text-center">
          <img src="/images/tik.png" className="tik-img" alt="tick" />
        </div>
        <div className="verified-head text-center">
          <p>Email Verified</p>
        </div>
        <div className="verified-text text-center">
          <p>Logging you in...</p>
        </div>
      </div>
    </>
  );
}

export default Verfied;
