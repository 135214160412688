import React from "react";

import "./App.css";
// import {Routes,Route} from 'react-router-dom';
import { BrowserRouter, Route, Routes, HashRouter } from "react-router-dom";

import AppLayouts from "./layouts/AppLayout";
import Dashboard from "./views/Dashboard";
import Login from "./views/Login";
import Signup from "./views/Signup";
import Verified from "./views/Verified";
import NewTopic from "./views/topic/NewTopic";
import SingleTopic from "./views/topic/SingleTopic";
import Profile from "./views/Profile";
import PublicProfile from "./views/PublicProfile";
import Setting from "./views/settings/Setting";
import ChangeUsername from "./views/settings/ChangeUsername";
import ChangeEmail from "./views/settings/ChangeEmail";
import ChangePassword from "./views/settings/ChangePassword";
import VerifyEmail from "./views/VerifyEmail";
import ProtectedRoute from "./components/ProtectedRoute";
import Notification from "./views/Notification";
import Bookmarks from "./views/Bookmarks";
import UpdatePassword from "./views/UpdatePassword";
import ForgetPassword from "./views/ForgetPassword";

function App() {
  return (
    // <div className="App">
    <BrowserRouter>
      <Routes>
        <Route path="login" exact name="Login" element={<Login />} />
        <Route path="signup" exact name="Login" element={<Signup />} />

        <Route path="verify-email" exact name="Verify Email page" element={<VerifyEmail />} />
        <Route path="verified" exact name="verified page" element={<Verified />} />
        <Route path="forget-password" exact name="verified page" element={<ForgetPassword />} />
        <Route path="update-password/:uid/:token" exact name="verified page" element={<UpdatePassword />} />

        <Route path="/" element={<AppLayouts />}>
          <Route path="/" exact name="Dashboard page" element={<Dashboard />} />
          <Route path="dashboard" exact name="Dashboard page" element={<Dashboard />} />
          <Route path="topic/:slug" exact name="SingleTopic page" element={<SingleTopic />} />
          <Route
            path="new-topic"
            exact
            name="NewTopic page"
            element={
              <ProtectedRoute>
                <NewTopic />
              </ProtectedRoute>
            }
          />
          <Route
            path="public-profile/:username"
            exact
            name="PublicProfile page"
            element={
              <ProtectedRoute>
                <PublicProfile />
              </ProtectedRoute>
            }
          />
          <Route
            path="profile"
            exact
            name="Profile page"
            element={
              <ProtectedRoute>
                <Profile />
              </ProtectedRoute>
            }
          />
          <Route
            path="settings"
            exact
            name="Setting page"
            element={
              <ProtectedRoute>
                <Setting />
              </ProtectedRoute>
            }
          />
          <Route
            path="settings/change-username"
            exact
            name="Setting page"
            element={
              <ProtectedRoute>
                <ChangeUsername />{" "}
              </ProtectedRoute>
            }
          />
          <Route
            path="settings/change-email"
            exact
            name="Change Email page"
            element={
              <ProtectedRoute>
                <ChangeEmail />
              </ProtectedRoute>
            }
          />
          <Route
            path="settings/change-password"
            exact
            name="Change Email page"
            element={
              <ProtectedRoute>
                <ChangePassword />
              </ProtectedRoute>
            }
          />
          <Route
            path="notification"
            exact
            name="Notification page"
            element={
              <ProtectedRoute>
                {" "}
                <Notification />{" "}
              </ProtectedRoute>
            }
          />
          <Route
            path="bookmarks"
            exact
            name="bookmarks page"
            element={
              <ProtectedRoute>
                <Bookmarks />
              </ProtectedRoute>
            }
          />
        </Route>
      </Routes>
    </BrowserRouter>
    // </div>
  );
}

export default App;
