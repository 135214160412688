import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import Auth from "../components/Auth";
import { useNavigate } from "react-router-dom";

const ForgetPassword = () => {
  const { register, handleSubmit, formState: { errors }, setValue } = useForm({
    defaultValues: {
      email: ""
    },
  });

  const { basehttp } = Auth();
  const [submitError, setSubmitError] = useState({});
  const [submitSuccess, setSubmitSuccess] = useState({});
  const [btnLoader, setBtnLoader] = useState(false);
  //const [validEmail, setValidEmail] = useState("");

  const navigate = useNavigate();



  const submitForm = (data) => {
    console.log(data)
    setBtnLoader(true);
    basehttp
      .post("/forgot-password", data)
      .then((res) => {
        setBtnLoader(false);
        console.log(res.data.error);
        if (res.data.statusCode===403) {
          setSubmitError(res.data);
        } else if(res.data.statusCode===200) {
          navigate('/login')
        }
      })
  };

  

 
  return (
    <>
      <div className="form-block login-sec">
        <form onSubmit={handleSubmit(submitForm)}>
          <div className="row mb-3">
            <div className="col-12">
              <Link to="#">
                <img
                  src="/images/logo.svg"
                  alt="img"
                  className="img-fluid"
                />
              </Link>
            </div>
          </div>
          <div className="row mt-5 mb-3">
            <div className="col-12">
              <h3>Reset your password</h3>
              <p>Enter your email address to reset your password</p>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-12">
              {/* <label htmlFor="email" className="form-label">
               Email
              </label> */}
              <input
                type="email"
                className="form-control"
                id="email"
                placeholder="Enter Email Address"
                {...register("email", { required: true })}
              />
              {submitError.message && (
                <div className="text-danger text-left">
                  {submitError.message}
                </div>
              )}
              {submitError.error && (
                <div className="text-danger text-left">
                  {submitError.error}
                </div>
              )}
              
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-12">
              {btnLoader ? (
                <button className="btn btn-primary" type="button">
                  Loading..
                  <span className="spinner-border spinner-border-sm"></span>
                </button>
              ) : (
                <button type="submit" className="btn btn-black">
                  Send
                </button>
              )}
            </div>
          </div>
          <hr/>
          Have your username and password ? <Link to={'/login'} className="topic_link">Login Now</Link>
        </form>
      </div>
    </>
  );
};

export default ForgetPassword;
