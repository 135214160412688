
import React,{useState} from "react";
import { useForm } from "react-hook-form";
import Auth from "../../components/Auth"



const ChangeUsername = () => {
  const {http}=Auth();

  const { register, handleSubmit,formState: { errors },reset , watch } = useForm();
  const [btnLoader,setBtnLoader]=useState(false)
  const [resError, setResError] = useState('');
  const [resSuccess, setResSuccess] = useState('');

  const onSubmit = (data) => {
    setBtnLoader(true)
    setResError('')
    http.post('/change-password',data).then((res)=>{
        console.log(res.data.statusCode);
        if (res.data.statusCode===403) {
          setResError(res.data.message)
        } else if (res.data.statusCode===200){
          reset()
          setResSuccess(res.data.message)
        }
        setBtnLoader(false)
      }).catch(function (error) {
        setBtnLoader(false)
      })
  };
  const newPassword = watch("password");



  return (
    <>
     <div className="center-panel">
        <div className="page-content">
          <div className="page-head">
            <h1>Change Password</h1>
          </div>
          <div className="page-body">
            <div className="form-block">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row mb-3">
                  <div className="col-12 col-md-12 col-lg-6">
                    <label htmlFor="old_password" className="form-label">Enter Current Password</label>
                    <input type="password" className="form-control" id="old_password" {...register("old_password", { 
                      // required: true,minLength:6 
                      required: "Old password is required",
                      minLength: {
                        value: 6,
                        message: "Old password must be at least 6 characters long"
                      }

                      }
                      )} />
                    {errors.old_password && (
                        <p className="error-text">{errors.old_password.message}</p>
                      )}
                      {resError && <div className='text-danger text-left'>{resError}</div>}
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-12 col-md-12 col-lg-6">
                    <label htmlFor="password" className="form-label">Enter New Password</label>
                    <input type="password" className="form-control" id="password"  {...register("password", {   
                      required: "Password is required",
                      minLength: {
                        value: 6,
                        message: "Password must be at least 6 characters long"
                      }
                       })} />
                    {errors.password && (
                      <p className="error-text">{errors.password.message}</p>
                    )}
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-12 col-md-12 col-lg-6">
                    <label htmlFor="confirm_password" className="form-label">Confirm New Password</label>
                    <input type="password" className="form-control" id="confirm_password" {...register("confirmPassword", {
                    required: true,
                    validate: (value) => value === newPassword, 
                })} />
                  {errors.confirmPassword?.type === "required" && (
                    <p className="error-text">Confirm Password is required</p>
                  )}
                  {errors.confirmPassword?.type === "validate" && (
                    <p className="error-text">Passwords do not match</p>
                  )}
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-12">


                  {  btnLoader ?
                      <button className="btn btn-primary" type="button">
                        Loading..
                        <span className="spinner-border spinner-border-sm"></span>
                      </button> 
                    :
                    <button type="submit" className="btn btn-black">Submit</button>

                    }
                    
                  </div>
                </div>
                <div className="row mb-3 success-row">
                  <div className="col-12">
                  {resSuccess &&  <p><i className="fa-sharp fa-solid fa-circle-check" /> Password changed successfully</p>}
                   
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ChangeUsername