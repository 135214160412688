import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Auth from "../components/Auth";

const Profile = () => {
  const { http, logout } = Auth();
  const [user, setUser] = useState(false);

  const getFetchData = () => {
    http
      .post("/profile")
      .then((res) => {
        if (!res.data.status) {
          logout();
        }
        setUser(res.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    getFetchData();
  }, []);

  return (
    <>
      <div className="center-panel">
        {user ? (
          <div className="page-content">
            <div className="page-head profile-head">
              <div className="row">
                <div className="col-6 col-md-8 d-flex align-items-center">
                  <h1>@{user.username}</h1>
                </div>
                {/* <div className="col-6 col-md-4 align-right">
                <a href="#" className="btn btn-black btn-follow">Follow</a>
              </div> */}
              </div>
            </div>
            <div className="page-body">
              <div className="profile-nav">
                <a href="#topics" className="active">
                  Topics ({user.topicsCount})
                </a>
                <a href="#comments">Comments ({user.commentsCount})</a>
              </div>
              <div className="profile-sec">
                <div className="tab-sec active" id="topics">
                  <div className="topics">
                    {user
                      ? Object.keys(user.topics).map((k) => (
                          <div className="topic-block">
                            <Link to={"/topic/" + user.topics[k].slug} className="title">
                              {user.topics[k].title}
                            </Link>
                            <Link href="#" className="user">
                              @{user.topics[k].user}
                            </Link>
                            <span className="comments">{user.topics[k].comments} comments</span>
                          </div>
                        ))
                      : ""}
                  </div>
                </div>
                {/* <div className="tab-sec" id="comments">
                <div className="comments">
                  <div className="block">
                    <div className="user-info">
                      <a href="#" className="username">@john_doe</a>
                      <span className="post-time separator">10h</span>
                    </div>
                    <div className="text">
                      <p>That's exactly what I always thought</p>
                    </div>
                    <div className="stats">
                      <div className="like">
                        <a href="#"><i className="fa-light fa-thumbs-up" /> Like</a>
                        (<span>89</span>)
                      </div>
                      <div className="comment">
                        <a href="#"><i className="fa-light fa-message" /> Comment</a>
                        (<span>12</span>)
                      </div>
                    </div>
                  </div>
                  <div className="block">
                    <div className="user-info">
                      <a href="#" className="username">@john_doe</a>
                      <span className="post-time separator">10h</span>
                    </div>
                    <div className="text">
                      <p>That's exactly what I always thought</p>
                    </div>
                    <div className="stats">
                      <div className="like">
                        <a href="#"><i className="fa-light fa-thumbs-up" /> Like</a>
                        (<span>89</span>)
                      </div>
                      <div className="comment">
                        <a href="#"><i className="fa-light fa-message" /> Comment</a>
                        (<span>12</span>)
                      </div>
                    </div>
                    <div className="block sub-comment">
                      <div className="user-info">
                        <a href="#" className="username">@john_doe</a>
                        <span className="post-time separator">10</span>
                      </div>
                      <div className="text">
                        <p>Thats exactly what I always thought</p>
                      </div>
                      <div className="stats">
                        <div className="like">
                          <a href="#"><i className="fa-light fa-thumbs-up" /> Like</a>
                          (<span>89</span>)
                        </div>
                        <div className="comment">
                          <a href="#"><i className="fa-light fa-message" /> Comment</a>
                          (<span>12</span>)
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tab-sec" id="visits">
                <div className="visits">
                </div>
              </div> */}
              </div>
            </div>
          </div>
        ) : (
          <div className="box">
            <div className="loader">
              <span className="dot"></span>
              <span className="dot"></span>
              <span className="dot"></span>
              <span className="dot"></span>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Profile;
