import React, { useState, useEffect, Component } from "react";
import { Route, Link, useParams, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import Auth from "../../components/Auth";

const SingleTopic = () => {
  const { register, handleSubmit, reset } = useForm();
  const [btnLoader, setBtnLoader] = useState(false);
  const navigate = useNavigate();
  const { http, logout } = Auth();
  const [topic, setTopic] = useState();
  const [id, setId] = useState();
  const [topicComment, setTopicComment] = useState();
  const [showComment, setShowComment] = useState(false);
  const [upvote, setUpvote] = useState(false);
  const [downvote, setDownvote] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const isAuthenticated = () => {
    // Implement your authentication logic here, e.g., check for a token in local storage
    return localStorage.getItem("token") !== null;
  };
  const checkUserToken = () => {
    const userToken = sessionStorage.getItem("userToken");
    // console.log(userToken);
    if (!userToken || userToken === "undefined") {
      setIsLoggedIn(false);
    } else {
      setIsLoggedIn(true);
    }
  };
  const toggleCommentBox = () => {
    setShowComment(!showComment);
  };
  const addTopicUpvote = () => {
    let data = { topic_id: id };
    http
      .post("/addTopicUpVote", data)
      .then((res) => {
        if (!res.data.status) {
          logout();
        }
        console.log(res.data.status);
        if (res.data.status) {
          getFetchData();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const addTopicDownvote = () => {
    let data = { topic_id: id };
    http
      .post("/addTopicDownVote", data)
      .then((res) => {
        if (!res.data.status) {
          logout();
        }
        console.log(res.data.status);
        if (res.data.status) {
          getFetchData();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const removeTopicUpvote = () => {
    let data = { topic_id: id };
    http
      .post("/removeTopicUpVote", data)
      .then((res) => {
        if (!res.data.status) {
          logout();
        }
        console.log(res.data.status);
        if (res.data.status) {
          getFetchData();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const removeTopicDownvote = () => {
    let data = { topic_id: id };
    http
      .post("/removeTopicDownVote", data)
      .then((res) => {
        if (!res.data.status) {
          logout();
        }
        console.log(res.data.status);
        if (res.data.status) {
          getFetchData();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const likeComment = (id) => {
    let data = { comment_id: id };
    http
      .post("/likeComment", data)
      .then((res) => {
        if (!res.data.status) {
          logout();
        }
        console.log(res.data.status);
        if (res.data.status) {
          getFetchData();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const removeLikeComment = (id) => {
    let data = { comment_id: id };
    http
      .post("/unlikeComment", data)
      .then((res) => {
        if (!res.data.status) {
          logout();
        }
        console.log(res.data.status);
        if (res.data.status) {
          getFetchData();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const addBookmark = () => {
    let data = { topic_id: id };
    http
      .post("/addTopicBookmark", data)
      .then((res) => {
        if (!res.data.status) {
          logout();
        }
        if (res.data.status) {
          getFetchData();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const removeBookmark = () => {
    let data = { topic_id: id };
    http
      .post("/removeTopicBookmark", data)
      .then((res) => {
        if (!res.data.status) {
          logout();
        }
        if (res.data.status) {
          getFetchData();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const { slug } = useParams();
  //console.log(title);
  const addCommentForm = (data) => {
    setBtnLoader(true);
    http
      .post("/addComment", data)
      .then((res) => {
        if (!res.data.status) {
          logout();
        }
        console.log(res.data.status);
        if (res.data.status) {
          reset();
          getFetchData();
        }
        setBtnLoader(false);
      })
      .catch(function (error) {
        setBtnLoader(false);
      });
  };
  const visitTopic = () => {
    let data = {
      topic_id: slug,
    };
    http
      .post("/visitTopic", data)
      .then((res) => {
        console.log(res.data.status);

        setBtnLoader(false);
      })
      .catch(function (error) {
        setBtnLoader(false);
      });
  };

  const getFetchData = () => {
    let reqUrl = `/topic/${slug}`;
    http
      .get(reqUrl)
      .then(async (res) => {
        /*if (!res.data.status) {
          logout();
        }*/
        setTopic(res.data.data);
        setId(res.data.data.key);
        setTopicComment(res.data.data.comments);
        setUpvote(res.data.data.isUpVoted);
        setDownvote(res.data.data.isDownVoted);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    getFetchData();
    visitTopic();
    checkUserToken();
  }, []);

  const showCommentBox = (e) => {
    if (!isLoggedIn) {
      logout();
    }
    e.preventDefault();
    const closestElement = e.target.closest(".stats");
    if (closestElement) {
      const foundElement = closestElement.querySelector(".enter-comment");
      foundElement.classList.toggle("active");
    }
  };

  const addLoader = (e) => {
    e.classList.remove("btn-black");
    e.classList.add("btn-primary");
    e.setAttribute("type", "button");
    e.innerHTML = ' Loading..<span className="spinner-border spinner-border-sm"></span>';
  };
  const hideLoader = (e) => {
    e.classList.remove("btn-primary");
    e.classList.add("btn-black");
    e.setAttribute("type", "submit");
    e.innerHTML = "Post";
  };
  const resetCommentForm = (e) => {
    e.target.querySelector(".subCommentText").value = "";
  };

  const handleSubCommentSubmit = (e) => {
    e.preventDefault();
    let comment = e.target.querySelector(".subCommentText").value;
    let id = e.target.querySelector(".commentID").value;
    let btn = e.target.querySelector('[type="submit"]');
    let data = {
      text: comment,
      comment_id: id,
      comment_type: "comment",
    };
    addLoader(btn);
    http
      .post("/addComment", data)
      .then((res) => {
        if (!res.data.status) {
          logout();
        }
        if (res.data.status) {
          getFetchData();
          //hideLoader()
        }
        setBtnLoader(false);
      })
      .catch(function (error) {
        setBtnLoader(false);
      });
  };

  function Comment({ datakey, comment }) {
    return (
      <div className="block" key={datakey}>
        <div className="user-info">
          <Link to={`/public-profile/${comment.user}`} className="username">
            @{comment.user}
          </Link>
          <span className="post-time separator">{comment.till_time}h</span>
        </div>
        <div className="text">
          <p>{comment.text}</p>
        </div>
        <div className="stats">
          {comment.isLiked ? (
            <div className={`like liked`}>
              <Link onClick={() => removeLikeComment(datakey)}>
                <i className="fa-light fa-thumbs-up" />
                Liked
              </Link>
              (<span>{comment.likes_count}</span>)
            </div>
          ) : (
            <div className={`like`}>
              <Link onClick={() => likeComment(datakey)}>
                <i className="fa-light fa-thumbs-up" /> Like
              </Link>
              (<span>{comment.likes_count}</span>)
            </div>
          )}
          <div className="comment ">
            <Link className="replyCommentBtn" onClick={showCommentBox}>
              <i className="fa-light fa-message" />
              Comment
            </Link>
            (<span>{comment.comments_count}</span>)
          </div>

          <div className="enter-comment enter-reply-comment">
            <form onSubmit={handleSubCommentSubmit}>
              <input type="hidden" className="commentID" value={datakey} />
              <textarea name={`commet${datakey}`} id={`commet${datakey}`} className="form-control subCommentText" rows="3" placeholder="Write you comment"></textarea>
              <button className="btn btn-black" type="submit">
                Post
              </button>
            </form>
          </div>
        </div>
        {comment.comments_count > 0 && (
          <div className="block sub-comment">
            {Object.keys(comment.comments).map((nestedCommentKey) => (
              <>
                <div className="user-info">
                  <Link to={`/public-profile/${comment.comments[nestedCommentKey].user}`} className="username">
                    @{comment.comments[nestedCommentKey].user}
                  </Link>
                  <span className="post-time separator">{comment.comments[nestedCommentKey].till_time}h</span>
                </div>
                <div className="text">
                  <p>{comment.comments[nestedCommentKey].text}</p>
                </div>
                <div className="stats">
                  {comment.comments[nestedCommentKey].isLiked ? (
                    <div className={`like liked`}>
                      <Link onClick={() => removeLikeComment(nestedCommentKey)}>
                        <i className="fa-light fa-thumbs-up" />
                        Liked
                      </Link>
                      (<span>{comment.comments[nestedCommentKey].likes_count}</span>)
                    </div>
                  ) : (
                    <div className={`like`}>
                      <Link onClick={() => likeComment(nestedCommentKey)}>
                        <i className="fa-light fa-thumbs-up" /> Like
                      </Link>
                      (<span>{comment.comments[nestedCommentKey].likes_count}</span>)
                    </div>
                  )}

                  <div className="comment">
                    <Link className="replyCommentBtn" onClick={showCommentBox}>
                      <i className="fa-light fa-message"></i> Comment
                    </Link>
                    (<span>{comment.comments[nestedCommentKey].comments_count}</span>)
                  </div>
                  <div className="enter-comment enter-reply-comment">
                    <form onSubmit={handleSubCommentSubmit}>
                      <input type="hidden" className="commentID" value={nestedCommentKey} />
                      <textarea name={`commet${nestedCommentKey}`} id={`commet${nestedCommentKey}`} className="form-control subCommentText" rows="3" placeholder="Write you comment"></textarea>
                      <button className="btn btn-black" type="submit">
                        Post
                      </button>
                    </form>
                  </div>
                </div>

                {Object.keys(comment.comments[nestedCommentKey].comments).map((innerCommentKey) => (
                  <div className="inner-comment" key={innerCommentKey}>
                    <div className="user-info">
                      <Link className="username">@{comment.comments[nestedCommentKey].comments[innerCommentKey].user}</Link>
                      <span className="post-time separator">{comment.comments[nestedCommentKey].comments[innerCommentKey].till_time}h</span>
                    </div>
                    <div className="text">
                      <p>{comment.comments[nestedCommentKey].comments[innerCommentKey].text}</p>
                    </div>
                  </div>
                ))}
              </>
            ))}
          </div>
        )}
      </div>
    );
  }
  function CommentSection({ comments }) {
    return (
      <div>
        {Object.keys(comments).map((data) => (
          <Comment datakey={data} comment={comments[data]} />
        ))}
      </div>
    );
  }

  return (
    <>
      <div className="center-panel">
        {topic ? (
          <div className="single-topic">
            <div className="topic-head">
              <div className="user-info">
                <Link to={`/public-profile/${topic.user}`} className="username">
                  @{topic.user}
                </Link>
                <span className="post-time separator">{topic.till_time}h</span>
              </div>
              <div className="title">
                <h1>{topic ? topic.title : ""}</h1>
                <p>{topic.content}</p>
              </div>
              <div className="below-title">
                <div className="vote">
                  {upvote ? (
                    <div className="upvote voted">
                      <Link onClick={removeTopicUpvote}>
                        <i className="fa-solid fa-arrow-up" />
                        <span className="text">Upvoted</span>
                        <span className="count separator">{topic.upvotes}</span>
                      </Link>
                    </div>
                  ) : (
                    <div className="upvote">
                      <Link onClick={addTopicUpvote}>
                        <i className="fa-solid fa-arrow-up" />
                        <span className="text">Upvote</span>
                        <span className="count separator">{topic.upvotes}</span>
                      </Link>
                    </div>
                  )}
                  {downvote ? (
                    <div className="downvote voted">
                      <Link onClick={removeTopicDownvote}>
                        <i className="fa-solid fa-arrow-down" />
                        <span className="text">Downvoted</span>
                        <span className="count separator">{topic.downvotes}</span>
                      </Link>
                    </div>
                  ) : (
                    <div className="downvote">
                      <Link onClick={addTopicDownvote}>
                        <i className="fa-solid fa-arrow-down" />
                        <span className="text">Downvote</span>
                        <span className="count separator">{topic.downvotes}</span>
                      </Link>
                    </div>
                  )}
                </div>
                <div className="comment-btn" onClick={toggleCommentBox}>
                  <Link href="javascript:void(0);">
                    <i className="fa-light fa-comment" /> Comment
                  </Link>
                </div>

                {topic.isBookmarked ? (
                  <div className="bookmark-btn" onClick={removeBookmark}>
                    <Link href="javascript:void(0);">
                      <i className="fa fa-bookmark"></i> Bookmarked
                    </Link>
                  </div>
                ) : (
                  <div className="bookmark-btn" onClick={addBookmark}>
                    <Link href="javascript:void(0);">
                      <i className="fa-regular fa-bookmark"></i> Bookmark
                    </Link>
                  </div>
                )}
              </div>
            </div>
            <div className="comments">
              <form onSubmit={handleSubmit(addCommentForm)}>
                {showComment ? (
                  <div className="enter-comment main-comment">
                    <input type="hidden" {...register("topic_id")} defaultValue={id} />
                    {/* <input type="hidden" {...register('comment_type')} defaultValue="comment"/> */}

                    <textarea className="form-control" rows={3} placeholder="Write you comment" defaultValue={""} {...register("text", { required: true })} />
                    {btnLoader ? (
                      <button className="btn btn-primary" type="button">
                        Loading..
                        <span className="spinner-border spinner-border-sm"></span>
                      </button>
                    ) : (
                      <button type="submit" className="btn btn-black">
                        Post
                      </button>
                    )}
                  </div>
                ) : (
                  ""
                )}
              </form>

              {topic ? (
                <CommentSection comments={topicComment} />
              ) : (
                <div className="box">
                  <div className="loader">
                    <span className="dot"></span>
                    <span className="dot"></span>
                    <span className="dot"></span>
                    <span className="dot"></span>
                  </div>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="box">
            <div className="loader">
              <span className="dot"></span>
              <span className="dot"></span>
              <span className="dot"></span>
              <span className="dot"></span>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default SingleTopic;
