import React from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Auth from "../components/Auth";

function Bookmark() {
  const { http, logout } = Auth();
  const [topics, setTopics] = useState();
  const [loading, setLoading] = useState(true);
  const getFetchData = () => {
    let reqUrl = `/bookmarkTopic`;
    http
      .get(reqUrl)
      .then((res) => {
        // if(res.data.statusCode==0){
        //   logout()
        // }
        setTopics(res.data.data);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    getFetchData();
  }, []);

  const removeBookmark = (id) => {
    let data = { topic_id: id };
    http
      .post("/removeTopicBookmark", data)
      .then((res) => {
        if (res.data.status) {
          getFetchData();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <>
      <div className="center-panel">
        {/* <div className="search">
            <input type="text" placeholder="Search" />
        </div> */}
        <div className="topics-wrapper">
          {loading ? (
            <div className="box">
              <div className="loader">
                <span className="dot"></span>
                <span className="dot"></span>
                <span className="dot"></span>
                <span className="dot"></span>
              </div>
            </div>
          ) : topics && topics != "" ? (
            Object.keys(topics).map((key) => (
              <div className="topic-block" key={key}>
                <Link to={"/topic/" + topics[key].slug} className="title">
                  {topics[key].title}
                </Link>
                <Link to={`/public-profile/${topics[key].user}`} className="user">
                  @{topics[key].user}
                </Link>
                <span className="comments">{topics[key].comments} comments</span>
                <span className="bookmark-btn" onClick={() => removeBookmark(key)}>
                  <Link>
                    <i className="fa fa-bookmark"></i> Bookmarked
                  </Link>
                </span>

                {/* {topics[key].isBookmarked?
                <span className="bookmark-btn" onClick={()=>removeBookmark(key)}> 
                  <Link href="javascript:void(0);"><i className="fa fa-bookmark"></i> Bookmarked</Link>
                </span>
                :
                <span className="bookmark-btn" onClick={()=>addBookmark(key)}> 
                  <Link href="javascript:void(0);"><i className="fa-regular fa-bookmark"></i> Bookmark</Link>
                </span>
              } */}
              </div>
            ))
          ) : (
            <div className="not-found-msg">Bookmarked not found</div>
          )}
        </div>
      </div>
    </>
  );
}

export default Bookmark;
