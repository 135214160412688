import React, { useState } from "react";
import { Link } from "react-router-dom";
import Auth from "../../components/Auth";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

const NewTopic = () => {
  const { http } = Auth();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const [btnLoader, setBtnLoader] = useState(false);

  const onSubmit = (data) => {
    setBtnLoader(true);
    http
      .post("/saveTopic", data)
      .then((res) => {
        console.log(res.data.status);
        if (res.data.status) {
          reset();
          navigate("/dashboard");
          //toast.success(res.data.message,'!Success')
        }
        setBtnLoader(false);
      })
      .catch(function (error) {
        setBtnLoader(false);
      });
  };

  // const handleSave = (event) => {
  //   const { name, value } = event.target;
  //   let errorData = { ...errors };
  //   const errorMessage = validateProperty(event);
  //   if (errorMessage) {
  //     errorData[name] = errorMessage;
  //   } else {
  //     delete errorData[name];
  //   }
  //   let topicData = { ...topic };
  //   topicData[name] = value;
  //   setTopic(topicData);
  //   setErrors(errorData);
  // };

  console.log(errors);
  return (
    <>
      <div className="center-panel">
        <div className="page-content">
          <div className="page-head">
            <h1>Post New Topic</h1>
          </div>
          <div className="page-body">
            <div className="post-topic">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row mb-3">
                  <div className="col-12">
                    <label htmlFor="your_title" className="form-label">
                      Your title<span>*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="your_title"
                      //name="title"
                      {...register("title", { required: true })}
                    />
                    {errors.title && <div className="text-danger">This field is required</div>}
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-12">
                    <label htmlFor="write_content" className="form-label">
                      Write content<span className="required-tag">*</span>
                    </label>
                    <textarea
                      rows={8}
                      className="form-control"
                      id="write_content"
                      // name="content"
                      {...register("content", { required: true })}
                    />
                    {errors.content && <div className="text-danger">This field is required</div>}
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-12">
                    {btnLoader ? (
                      <button className="btn btn-primary" type="button">
                        Loading..
                        <span className="spinner-border spinner-border-sm"></span>
                      </button>
                    ) : (
                      <button type="submit" className="btn btn-black">
                        Post
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewTopic;
