import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import Auth from "../components/Auth";
import { useNavigate } from "react-router-dom";

const VerifyEmail = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      email: "",
      otp: "",
    },
  });

  const { basehttp, setUserToken } = Auth();
  const [submitError, setSubmitError] = useState({});
  const [btnLoader, setBtnLoader] = useState(false);
  const [validEmail, setValidEmail] = useState("");
  const [showResendButton, setShowResendButton] = useState(false);
  const [canResend, setCanResend] = useState(true);
  const [countdown, setCountdown] = useState(30);

  const navigate = useNavigate();

  const handleResendClick = () => {
    if (canResend) {
      //console.log('Opt resent');
      let resendData = { email: validEmail };
      console.log(resendData);
      basehttp.post("/resend-email", resendData).then((res) => {
        setBtnLoader(false);
        console.log(res.data.error);
        if (res.data.statusCode === "403") {
          setSubmitError(res.data);
        } else if (res.data.statusCode === 200) {
          setCanResend(false);
          setTimeout(() => {
            setCanResend(true);
            setCountdown(30);
          }, 30000);
        }
      });
    }
  };
  const submitForm = (data) => {
    setBtnLoader(true);
    data.email = validEmail;
    basehttp.post("/verify-email", data).then((res) => {
      setBtnLoader(false);
      console.log(res.data.error);
      if (res.data.statusCode == "403") {
        setSubmitError(res.data);
      } else {
        localStorage.removeItem("verify_email");
        setUserToken(res.data.token);
        navigate("/verified");
      }
    });
  };

  useEffect(() => {
    let getEmail = localStorage.getItem("verify_email");
    if (getEmail != "" && getEmail != undefined) {
      setValidEmail(localStorage.getItem("verify_email"));
    } else {
      navigate("/signup");
    }
  }, []);

  useEffect(() => {
    const timerToShowButton = setTimeout(() => {
      setShowResendButton(true);
    }, 30000);

    return () => clearTimeout(timerToShowButton);
  }, []);
  useEffect(() => {
    if (!canResend) {
      const countdownTimer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);

      return () => {
        clearInterval(countdownTimer);
      };
    }
  }, [canResend]);

  return (
    <>
      <div className="form-block login-sec">
        <form onSubmit={handleSubmit(submitForm)}>
          <div className="row mb-3">
            <div className="col-12">
              <Link to="#">
                <img src="/images/logo.svg" alt="img" className="img-fluid" />
              </Link>
            </div>
          </div>
          <div className="row my-5">
            <div className="col-12">
              <h3>Please verify your email address</h3>
              <p>We sent you a 6 digit OTP on your email {validEmail ? validEmail : ""}</p>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-12">
              <label htmlFor="verify_code" className="form-label">
                6-digit OTP code
              </label>
              <input type="number" className="form-control" id="verify_code" placeholder="Enter Otp" {...register("otp", { required: true })} />
              {submitError.message && <div className="text-danger text-left">{submitError.message}</div>}
              {submitError.error && <div className="text-danger text-left">{submitError.error}</div>}

              {/* {showResendButton && ( */}

              {canResend ? (
                <p className="resend-btn" onClick={handleResendClick}>
                  Resend Otp
                </p>
              ) : (
                <p className="disable-resend-btn" disabled="false">
                  Resend in {countdown} seconds
                </p>
              )}

              {/* )} */}
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-12">
              {btnLoader ? (
                <button className="btn btn-primary" type="button">
                  Loading..
                  <span className="spinner-border spinner-border-sm"></span>
                </button>
              ) : (
                <button type="submit" className="btn btn-black">
                  Verify
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default VerifyEmail;
