import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
// import {auth} from "../firebase"
// import { signInWithEmailAndPassword } from "firebase/auth";
import Auth from "../components/Auth";
import { useForm } from "react-hook-form";
import Signup from "./Signup";

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [btnLoader, setBtnLoader] = useState(false);
  const [submitError, setSubmitError] = useState({});
  const [resError, setResError] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { basehttp, http, setUserToken, getUserToken, getUser } = Auth();

  const submitForm = (data) => {
    setBtnLoader(true);
    basehttp
      .post("/login", data)
      .then((res) => {
        if (res.data.statusCode === 403) {
          setResError(res.data.message);
        } else if (res.data.error) {
          setResError(res.data.error);
        } else if (res.data.token) {
          setUserToken(res.data.token);
        } else {
          setResError(res.data.message);
        }
        setBtnLoader(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <>
      <div className="form-block login-sec">
        <form onSubmit={handleSubmit(submitForm)}>
          <div className="row mb-3">
            <div className="col-12">
              <Link to={"/dashboard"}>
                <img src="/images/logo.svg" alt="img" className="img-fluid" />
              </Link>
            </div>
          </div>
          <div className="row my-5">
            <div className="col-12">
              <h3>Log In Your Account</h3>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-12">
              <label htmlFor="email_address" className="form-label">
                Email Address
              </label>
              <input type="text" className="form-control" id="email_address" placeholder="johndoe@gmail.com" {...register("email", { required: true })} />
              {errors.email && <div className="text-danger text-left">This field is required</div>}
              {submitError.email && <div className="text-danger text-left">{submitError.email[0]}</div>}
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-12">
              <label htmlFor="password" className="form-label">
                Password
              </label>
              <input
                type="password"
                className="form-control"
                id="password"
                placeholder="********"
                {...register("password", {
                  required: "Password is required",
                  minLength: {
                    value: 6,
                    message: "Password must be at least 6 characters long",
                  },
                })}
              />

              {errors.password && <div className="text-danger text-left">{errors.password.message}</div>}
              {submitError.password && <div className="text-danger text-left">{submitError.password[0]}</div>}
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-12">
              {btnLoader ? (
                <button className="btn login-btn" type="button">
                  Loading..
                  <span className="spinner-border spinner-border-sm"></span>
                </button>
              ) : (
                <button type="submit" className="btn btn-black">
                  Log In
                </button>
              )}
            </div>
          </div>
          {resError ? (
            <div className="row mb-3">
              <div className="col-12">
                <p className="text-danger">{resError}</p>
              </div>
            </div>
          ) : (
            ""
          )}
          <Link to={"/forget-password"} className="forget-password">
            <strong>I forgot my password</strong>
          </Link>
          <hr />
          <div className="row mb-3">
            <div className="col-12">
              <p>
                Don't have an account ?{" "}
                <strong className="navigation" onClick={() => navigate("/signup")}>
                  Sign up
                </strong>
              </p>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default Login;
